import { shape, string } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import styles from './AxsLogo.module.scss';

import { logoAxsLogoWhite } from '../../../assets';
import DesktopContainer from '../../../components/desktopContainer';
import Translate from '../../../components/translate';

const AxsLogo = ({
    alt,
    imageClassName,
    src,
    style,
}) => (
    <div className={styles.container}>
        <Link className={styles.link} to="/">
            <img
                alt={alt}
                className={`${styles.image} ${imageClassName}`}
                src={src}
                style={style}
            />
            <DesktopContainer style={{ display: 'inline-block' }}>
                <Translate>.SRAM.COM</Translate>
            </DesktopContainer>
        </Link>
    </div>
);

AxsLogo.defaultProps = {
    alt: 'AXS',
    imageClassName: '',
    src: logoAxsLogoWhite,
    style: null,
};

AxsLogo.propTypes = {
    alt: string,
    imageClassName: string,
    src: string,
    style: shape({}),
};

export default AxsLogo;
