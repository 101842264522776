import { string } from 'prop-types';
import React from 'react';

import styles from './MenuItem.module.scss';

const MenuItem = ({ className, ...otherProps }) => (
    <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
        className={`${styles.container} ${className}`}
    />
);

MenuItem.defaultProps = {
    className: '',
};

MenuItem.propTypes = {
    className: string,
};

export default MenuItem;
