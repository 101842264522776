import { func, number, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

import styles from './Slider.module.scss';

import StyledSlider from '../../../components/slider';

const Slider = ({
    className,
    max,
    min,
    onChange,
    value,
    ...otherProps
}) => {
    const [sliderValue, setSliderValue] = useState(value);
    useEffect(
        () => {
            let newValue = value;

            if (newValue) {
                if (newValue > max) {
                    newValue = max;
                } else if (newValue < min) {
                    newValue = min;
                }

                onChange(newValue);
            }

            setSliderValue(newValue);
        },
        [max, min, value],
    );

    return (
        <div className={`${styles.container} ${className}`}>
            <StyledSlider
                /* eslint-disable react/jsx-props-no-spreading */
                {...otherProps}
                max={max}
                min={min}
                onChange={(_event, inValue) => setSliderValue(inValue)}
                onChangeCommitted={(_event, inValue) => onChange(inValue)}
                value={sliderValue}
            />
        </div>
    );
};

Slider.defaultProps = {
    className: '',
    onChange: () => {},
    value: null,
};

Slider.propTypes = {
    className: string,
    onChange: func,
    value: number,
};

export default Slider;
