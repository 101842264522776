import React, { useState } from 'react';

import styles from './BikeCard.module.scss';

import { defaultBike, logoAxsBlack } from '../../assets';
import BikeAlertBadge from '../bikeAlertBadge';
import ImageContainer from '../../components/imageContainer';
import Translate from '../../components/translate';
import { URL_STATIC_DATA } from '../../constants';
import { Bike } from '../../providers';

interface BikeCardProps {
    bike: Bike | any;
    children?: any;
    image?: string;
    notificationSet?: any;
    showAlertBadge?: boolean;
}

const BikeCard = ({
    bike,
    children,
    image,
    notificationSet,
    showAlertBadge,
}: BikeCardProps) => {
    const [showGrayOverlay, setShowGrayOverlay] = useState(false);

    const bikeImage = (bike && bike.image) && bike.image.replace('https://static.quarqnet.com/', URL_STATIC_DATA);

    return (
        <div className={styles.container}>
            <div className={styles.container}>
                <ImageContainer
                    alt=""
                    src={(image
                        || bikeImage
                        || defaultBike
                    )}
                    onLoad={() => setShowGrayOverlay(true)}
                />
                <div
                    className={styles.imageOverlayContainer}
                    style={{ backgroundImage: showGrayOverlay ? undefined : 'none' }}
                >
                    <div className={styles.title}>
                        {(bike && bike.name) || (
                            <Translate>BIKE</Translate>
                        )}
                    </div>
                    <div className={styles.tag}>
                        <div className={styles.tagBackground} />
                        <img alt="" className={styles.tagImage} src={logoAxsBlack} />
                        <div className={styles.alertBadgeContainer}>
                            <BikeAlertBadge
                                className={styles.alertBadgeImage}
                                notificationSet={notificationSet}
                                show={showAlertBadge!}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {children}
        </div>
    );
};

export default BikeCard;
