import { string } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import styles from './MenuItemButton.module.scss';

const MenuItemButton = ({ className, ...otherProps }) => (
    <Link
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
        className={`${styles.container} ${className}`}
    />
);

MenuItemButton.defaultProps = {
    className: '',
};

MenuItemButton.propTypes = {
    className: string,
};

export default MenuItemButton;
