import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { shape, string } from 'prop-types';
import React from 'react';

import styles from './MessagesBadge.module.scss';

import { useNotifications } from '../../../providers/notifications/NotificationsContext';

const MessagesBadge = ({
    className,
    fontSize,
    style,
}) => {
    const { unreadCount } = useNotifications();
    const renderUnreadIcon = () => {
        if (!unreadCount) return null;

        return (
            <div className={styles.unreadCountContainer}>
                {(unreadCount < 100) ? unreadCount : '+'}
            </div>
        );
    };

    return (
        <div className={`${styles.container} ${className}`}>
            <NotificationsNoneIcon
                className={styles.logo}
                style={style}
                fontSize={fontSize}
            />
            {renderUnreadIcon()}
        </div>
    );
};

MessagesBadge.defaultProps = {
    className: '',
    fontSize: 'default',
    style: null,
};

MessagesBadge.propTypes = {
    className: string,
    fontSize: string,
    style: shape({}),
};

export default MessagesBadge;
