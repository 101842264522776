import { withSnackbar } from 'notistack';
import { useEffect } from 'react';

import NotificationSnackbar from '../notificationSnackbar';

import { useNotifications } from '../../providers/notifications/NotificationsContext';

const NotificationPoll = ({ enqueueSnackbar }) => {
    const notifications = useNotifications();
    useEffect(
        () => {
            const fetchNewActivityNotifications = async () => {
                for (let i = 0; i < 5; i++) {
                    // Loop await intentional here
                    // eslint-disable-next-line no-await-in-loop
                    await new Promise((resolve) => setTimeout(resolve, 3000));

                    // eslint-disable-next-line no-await-in-loop
                    const newNotifications = await notifications.fetchNewNotifications();

                    if (newNotifications && newNotifications.length) {
                        newNotifications
                            .filter(({ notification_type }) => notification_type === 'activity')
                            .forEach((notification) => enqueueSnackbar(
                                <NotificationSnackbar notification={notification} />,
                                { key: notification.id },
                            ));
                    }
                }
            };

            fetchNewActivityNotifications();
        },
        [],
    );

    return null;
};

export default withSnackbar(NotificationPoll);
