import React, { useEffect, useMemo, useState } from 'react';
import styles from './WheelsetSettings.module.scss';
import Translate from '../../../components/translate';
import TranslateOption from '../../../components/translateOption';
import CheckBox from '../../../components/checkbox';
import {
    getInnerRimWidthValues,
    getRimTypeOptions,
    getWheelDiameterValues,
    isGoodYearTire,
    RIM_TYPE_303_XPLR,
} from '../tyrePressureGuideHelpers2';

import WaffleBlock from '../../../components/waffleBlock';

interface WheelsetSettingInputsProps {
    checkedPreset: boolean,
    onChangePreset: () => void,
    wheelDiameter: number,
    rideStyle: string,
    rimType: string,
    innerRimWidth: number,
    onChange: (value: any) => void,
    isTireInches: boolean,
    showTireTypeRimWarning: boolean,
    rearTireCasing: string,
    frontTireCasing: string,
}

const WheelsetSetting = ({
    checkedPreset,
    onChangePreset,
    wheelDiameter,
    rideStyle,
    rimType,
    innerRimWidth,
    onChange,
    isTireInches,
    showTireTypeRimWarning,
    frontTireCasing,
    rearTireCasing,
}: WheelsetSettingInputsProps) => {
    const isGoodYearSelection = isGoodYearTire(frontTireCasing) && isGoodYearTire(rearTireCasing);
    const innerRimWidthValues = useMemo(() => getInnerRimWidthValues(rideStyle, rimType, isGoodYearSelection), [rideStyle, rimType, isGoodYearSelection]);
    const RIM_TYPE_OPTIONS = useMemo(() => getRimTypeOptions(rideStyle, rearTireCasing, frontTireCasing), [rideStyle, rearTireCasing, frontTireCasing]);
    const [innerRimwidtheError, setinnerRimwidtheError] = useState(false);

    const getWheelDiameterOption = (diameter: number) => {
        if (!isTireInches) {
            switch (diameter) {
                case 622:
                    return 'TIRE_PRESSURE_WHEEL_DIAMETER_622_NAME';
                case 584:
                    return 'TIRE_PRESSURE_WHEEL_DIAMETER_584_NAME';
                case 571:
                    return 'TIRE_PRESSURE_WHEEL_DIAMETER_571_NAME';
                default:
                    return `${diameter}`;
            }
        }

        switch (diameter) {
            case 622:
                return 'TIRE_PRESSURE_WHEEL_DIAMETER_622_INCHES';
            case 571:
                return 'TIRE_PRESSURE_WHEEL_DIAMETER_571_INCHES';
            case 559:
                return 'TIRE_PRESSURE_WHEEL_DIAMETER_559_INCHES';
            default:
                return `${diameter}`;
        }
    };

    const handleInnerRimWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (Number(e.target.value) > innerRimWidthValues.max || Number(e.target.value) < innerRimWidthValues.min) {
            setinnerRimwidtheError(true);
            return onChange({ innerRimWidth: e.target.value });
        }
        setinnerRimwidtheError(false);
        return onChange({ innerRimWidth: e.target.value });
    };

    const handleRimTypeChange = (value: string) => {
        if (value === RIM_TYPE_303_XPLR) {
            onChange({ innerRimWidth: 32, rimType: value });
        }
        onChange({ rimType: value });
    };

    const renderRideStylePreset = () => (
        <div className={styles.usePresetValuesContainer}>
            <div className={styles.checkboxContainer}>
                <CheckBox
                    disabled={!rideStyle}
                    checked={checkedPreset}
                    id="wheelSettingInputs__use-preset-values"
                    onChange={onChangePreset}
                    style={{ display: 'block', justifyContent: 'flex-end', opacity: !rideStyle ? 0.5 : 1 }}
                />
            </div>
            {/* jsx-a11y/label-has-associated-control disabled because not nesting label
                    is intentional here and the ID shouldn't exist twice
                */}
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label
                className={styles.usePresetValuesLabel}
                style={{ opacity: !rideStyle ? 0.5 : 1 }}
                htmlFor="wheelSettingInputs__use-preset-values"
            >
                <Translate>TIRE_PRESSURE_GUIDE_USE_PRESET</Translate>
            </label>
        </div>
    );

    const renderWheelDiameterInput = () => {
        const wheelDiameterValues = isGoodYearSelection ? [622] : getWheelDiameterValues(rideStyle);
        return (
            <div className={styles.section}>
                <Translate>WHEEL_DIAMETER</Translate>
                <div className={styles.inputContainer}>
                    <select
                        data-testid="wheel-diameter"
                        className={styles.inputField}
                        onChange={(e) => onChange({ wheelDiameter: e.target.value })}
                        required
                        value={!wheelDiameter ? '' : wheelDiameter}
                    >
                        <TranslateOption disabled hidden value="">SELECT</TranslateOption>
                        {wheelDiameterValues.map((wheelDiameterValue) => (
                            <TranslateOption key={wheelDiameterValue} value={wheelDiameterValue}>
                                {getWheelDiameterOption(wheelDiameterValue)}
                            </TranslateOption>
                        ))}
                    </select>
                </div>
            </div>
        );
    };

    const renderRimTypeInput = () => (
        <div className={styles.section}>
            <Translate>RIM_TYPE</Translate>
            <div className={styles.inputContainer}>
                <select
                    data-testid="rim-type"
                    className={styles.inputField}
                    onChange={(event) => handleRimTypeChange(event.target.value)}
                    required
                    value={rimType}
                >
                    <TranslateOption disabled hidden value="">SELECT</TranslateOption>

                    { RIM_TYPE_OPTIONS.map((rimTypeOption) => (
                        <TranslateOption key={rimTypeOption} value={rimTypeOption}>
                            {rimTypeOption}
                        </TranslateOption>
                    ))}

                </select>
            </div>
        </div>
    );

    const renderInnerRimWidthInput = () => {
        const step = isGoodYearSelection ? 0.5 : 1;

        return (
            <div className={styles.section}>
                <Translate>INNER_RIM_WIDTH</Translate>
                <div className={`${styles.inputContainer} ${showTireTypeRimWarning ? styles.warning : ''}`}>
                    <input
                        data-testid="inner-rim-width"
                        className={styles.inputWithUnits}
                        min={innerRimWidthValues.min}
                        max={innerRimWidthValues.max}
                        onChange={(e) => handleInnerRimWidthChange(e)}
                        placeholder="---"
                        required
                        step={step}
                        type="number"
                        value={innerRimWidth}
                        style={innerRimwidtheError ? { border: '1px solid red', color: 'red' } : {}}
                    />
                    <div className={styles.units}>
                        <Translate>UNITS_MM_SHORT</Translate>
                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (!RIM_TYPE_OPTIONS.includes(rimType)) {
            handleRimTypeChange(RIM_TYPE_OPTIONS[0]);
        }
    }, [RIM_TYPE_OPTIONS]);

    return (
        <div className={styles.container} key="WheelSetSettings">
            <Translate>WHEELSET</Translate>
            {renderRideStylePreset()}
            {renderWheelDiameterInput()}
            {renderRimTypeInput()}
            {renderInnerRimWidthInput()}
        </div>
    );
};

export default WheelsetSetting;
