import {
    bool,
    element,
    func,
    node,
    object,
    oneOfType,
    shape,
    string,
} from 'prop-types';
import React from 'react';

import styles from './NavBarDropdown.module.scss';

import Dropdown from '../dropdown';

import Translate from '../../../components/translate';

const NavBarDropdown = ({
    buttonClassName,
    buttonStyle,
    children,
    containerClassName,
    containerStyle,
    dropdownStyle,
    id,
    label,
    onAuxClick,
    onClick,
    open,
}) => (
    <Dropdown
        buttonClassName={buttonClassName}
        buttonContent={(
            <>
                <div style={{ textTransform: 'uppercase' }}>
                    <Translate>{label}</Translate>
                </div>
                {open && <div className={styles.backDrop} />}
            </>
        )}
        buttonStyle={buttonStyle}
        containerClassName={containerClassName}
        containerStyle={containerStyle}
        dropdownClassName={styles.dropdown}
        dropdownStyle={dropdownStyle}
        id={id}
        onAuxClick={onAuxClick}
        onClick={onClick}
        open={open}
    >
        {children}
    </Dropdown>
);

NavBarDropdown.defaultProps = {
    buttonClassName: '',
    buttonStyle: null,
    children: null,
    containerClassName: '',
    containerStyle: null,
    dropdownStyle: null,
    id: '',
    label: '',
    onAuxClick: null,
    onClick: null,
    open: false,
};

NavBarDropdown.propTypes = {
    buttonClassName: string,
    buttonStyle: shape({}),
    children: node,
    containerClassName: string,
    containerStyle: shape({}),
    // eslint-disable-next-line react/forbid-prop-types
    dropdownStyle: object,
    id: string,
    label: oneOfType([string, element]),
    onAuxClick: func,
    onClick: func,
    open: bool,
};

export default NavBarDropdown;
