import React, { ReactNode } from 'react';
import styles from './DesktopContainer.module.scss';

interface DesktopContainerProps{ className?: string, children: ReactNode, id?: string }

const DesktopContainer = ({ className = '', id, ...otherProps }: DesktopContainerProps) => (
    <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
        id={id}
        className={`${styles.container} ${className}`}
    />
);

export default DesktopContainer;
