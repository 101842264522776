import styles from './DrivetrainInput.module.scss';

import BikeFormRow from '../bikeFormRow';

import TranslateOption from '../../../components/translateOption';
import Translate, { useTranslation } from '../../../components/translate';
import { iconChainrings } from '../../../assets';
import { useSetState } from '../../../hooks';
import { useBikes } from '../../../providers';

const CUSTOM_GEARING = 'CUSTOM';

interface DrivetrainInputProps {
    disabled?: boolean;
    getValue: (key: string) => any;
    numberOfChainrings: number;
    numberOfCogs: number;
    onChange: (key: string, value: any) => void;
}

const DrivetrainInput = ({
    disabled = false,
    getValue,
    numberOfChainrings,
    numberOfCogs,
    onChange,
}: DrivetrainInputProps) => {
    // converting array into custom cassette string to display e.g. (11,12,13)
    const cassette = getValue('cassette');
    const parsedCassetteCogValues = (cassette && cassette.teeth) ? cassette.teeth.join(',') : '';

    const chainring = getValue('chainring');

    const [state, setState] = useSetState({
        cassetteCogValues: parsedCassetteCogValues,
        dualChainrings: chainring && (chainring.name === CUSTOM_GEARING && chainring.teeth.length > 1),
        singleChainring: chainring && (chainring.name === CUSTOM_GEARING && chainring.teeth.length === 1),
    });
    const { cassetteCogValues, dualChainrings, singleChainring } = state;
    const bikes:Record<string, any> = useBikes();
    const translate = useTranslation();

    function getChainringList() {
        const filteredChainrings = (numberOfChainrings === null)
            ? bikes.chainrings
            : bikes.chainrings.filter(({ 'Tooth Count': toothCount }: Record<string, any>) => (
                toothCount.length
            ));

        const chainringExists = (
            !chainring
            || !chainring.name
            || chainring.name === CUSTOM_GEARING
            || !!filteredChainrings.find(({ Name } : Record<string, any>) => Name === chainring.name)
        );

        if (!chainringExists) {
            return [
                {
                    Name: chainring.name,
                    'Tooth Count': chainring.teeth,
                },
                ...filteredChainrings,
            ];
        }

        return filteredChainrings;
    }

    function getCassetteList() {
        const filteredCassettes = (numberOfCogs === null || numberOfCogs === undefined)
            ? bikes.cassettes
            : bikes.cassettes.filter(({ Cogs }: Record<string, any>) => Cogs.length >= numberOfCogs);

        const cassetteExists = (
            !cassette
            || !cassette.name
            || cassette.name === CUSTOM_GEARING
            || !!filteredCassettes.find(({ Name }: Record<string, any>) => Name === cassette.name)
        );

        if (!cassetteExists) {
            return [
                {
                    Name: cassette.name,
                    'Tooth Count': cassette.teeth,
                },
                ...filteredCassettes,
            ];
        }

        return filteredCassettes;
    }

    function renderDualChainrings() {
        if (numberOfChainrings && numberOfChainrings !== 2) return null;

        let bigChainring = '';
        let smallChainring = '';

        if (dualChainrings) {
            [smallChainring, bigChainring] = chainring.teeth;

            bigChainring = bigChainring.toString();
            smallChainring = smallChainring.toString();
        }

        return (
            <BikeFormRow
                contentStyle={{ minWidth: 230 }}
                label="DRIVETRAIN_TWO_BY"
                labelClassName={styles.customChainringLabel}
                style={{ borderBottom: 'none' }}
            >
                <div
                    className={styles.inputContainer}
                    id="data-test-custom-dual-chainrings"
                    style={{ maxWidth: '15rem' }}
                >
                    <input
                        className={styles.input}
                        min={0}
                        onChange={(event) => {
                            const currentSmallChainring = (chainring.teeth.length) ? chainring.teeth[0] : 0;
                            const newChainringValues = [currentSmallChainring, Number(event.target.value)];

                            chainring.teeth = newChainringValues;

                            onChange('chainring', chainring);

                            setState({
                                dualChainrings: true,
                                singleChainring: false,
                            });
                        }}
                        placeholder={translate('BIG')}
                        style={{ marginRight: '0.5rem' }}
                        type="number"
                        value={bigChainring}
                    />
                    <input
                        className={styles.input}
                        min={0}
                        onChange={(event) => {
                            const currentBigChainring = (chainring.teeth.length > 1) ? chainring.teeth[1] : 0;
                            const newChainringValues = [Number(event.target.value), currentBigChainring];

                            chainring.teeth = newChainringValues;

                            onChange('chainring', chainring);

                            setState({
                                dualChainrings: true,
                                singleChainring: false,
                            });
                        }}
                        placeholder={translate('SMALL')}
                        type="number"
                        value={smallChainring}
                    />
                </div>
            </BikeFormRow>
        );
    }

    function renderSingleChainring() {
        if (numberOfChainrings && numberOfChainrings !== 1) return null;

        const singleChainringValue = singleChainring ? chainring.teeth[0].toString() : '';

        return (
            <BikeFormRow
                contentStyle={{ minWidth: 230 }}
                label="DRIVETRAIN_ONE_BY"
                labelClassName={styles.customChainringLabel}
            >
                <div
                    className={styles.inputContainer}
                    id="data-test-custom-single-chainring"
                    style={{ maxWidth: '15rem' }}
                >
                    <input
                        className={styles.input}
                        min="1"
                        onChange={(event) => {
                            const newChainRingValues = [Number(event.target.value) || ''];

                            chainring.teeth = newChainRingValues;

                            onChange('chainring', chainring);

                            setState({
                                dualChainrings: false,
                                singleChainring: true,
                            });
                        }}
                        placeholder={translate('ENTER')}
                        type="number"
                        value={singleChainringValue}
                    />
                </div>
            </BikeFormRow>
        );
    }

    function renderCustomCassette() {
        return (
            <BikeFormRow
                contentStyle={{ minWidth: 250 }}
                labelClassName={styles.customChainringLabel}
                style={{ borderBottom: 'none', overflowX: 'hidden', paddingTop: 0 }}
            >
                <div className={styles.inputContainer}>
                    <div className={styles.customCassetteSubText}>
                        <Translate>DRIVETRAIN_CASSETTE_CUSTOM_SUBTEXT</Translate>
                    </div>
                    <input
                        className={styles.input}
                        onChange={(event) => {
                            const newCassetteCogValuesArray = event.target.value
                                .split(',')
                                .map((gear) => {
                                    if (!gear.length) return null;

                                    return Number(gear);
                                });

                            cassette.teeth = newCassetteCogValuesArray;

                            onChange('cassette', cassette);
                            setState({ cassetteCogValues: event.target.value });
                        }}
                        pattern=" *\d+ *(, *\d+ *)*$"
                        placeholder="28,26,24,21,17,16,15,14,13,12,11"
                        type="text"
                        value={cassetteCogValues}
                    />
                </div>
            </BikeFormRow>
        );
    }

    function renderChainring() {
        const chainringName = (chainring && chainring.name) || '';
        const isCustom = chainringName === CUSTOM_GEARING;

        const chainringsList = getChainringList();

        return (
            <div className={`${styles.gearContainer} ${styles.chainringContainer}`}>
                <BikeFormRow
                    contentStyle={{ minWidth: 230 }}
                    image={iconChainrings}
                    labelClassName={styles.noLabel}
                    style={{ borderBottom: 'none' }}
                >
                    <div className={styles.inputContainer}>
                        <div className={styles.gearingLabel}>
                            <Translate>DRIVETRAIN_CHAINRINGS</Translate>
                        </div>
                        <select
                            className={styles.input}
                            disabled={disabled}
                            onChange={(event) => {
                                const isCustomChainring = (event.target.value === CUSTOM_GEARING);
                                const newChainring = isCustomChainring
                                    ? { 'Tooth Count': [] }
                                    : bikes.chainrings
                                        .find(({ Name }: Record<string, any>) => Name === event.target.value);

                                onChange('chainring', { name: event.target.value, teeth: newChainring['Tooth Count'] });

                                if (!isCustomChainring) {
                                    setState({
                                        dualChainrings: false,
                                        singleChainring: false,
                                    });
                                }
                            }}
                            value={chainringName}
                        >
                            <TranslateOption disabled hidden value="">
                                DRIVETRAIN_CHAINRINGS_PLACEHOLDER
                            </TranslateOption>
                            {chainringsList.map(({ Name }: any) => (
                                <option key={Name} value={Name}>
                                    {Name}
                                </option>
                            ))}
                            <TranslateOption value="CUSTOM">CUSTOM_DOTTED</TranslateOption>
                        </select>
                    </div>
                </BikeFormRow>
                {isCustom && renderDualChainrings()}
                {isCustom && renderSingleChainring()}
            </div>
        );
    }

    function renderCassette() {
        const cassetteList = getCassetteList();

        const cassetteName = (cassette && cassette.name) || '';
        const isCustom = cassetteName === CUSTOM_GEARING;

        return (
            <div className={styles.gearContainer}>
                <BikeFormRow
                    contentStyle={{ minWidth: 230 }}
                    labelClassName={styles.noLabel}
                    style={{ borderBottom: 'none' }}
                >
                    <div className={styles.inputContainer}>
                        <div className={styles.gearingLabel}>
                            <Translate>DRIVETRAIN_CASSETTE</Translate>
                        </div>
                        <select
                            className={styles.input}
                            disabled={disabled}
                            onChange={(event) => {
                                const isCustomCassette = (event.target.value === CUSTOM_GEARING);
                                const newCassette = isCustomCassette
                                    ? { Cogs: [] }
                                    : bikes.cassettes
                                        .find(({ Name }: Record<string, any>) => Name === event.target.value);

                                if (!isCustomCassette) {
                                    onChange('cassette', { name: event.target.value, teeth: newCassette.Cogs });

                                    setState({ cassetteCogValues: newCassette.Cogs.join(',') });

                                    return;
                                }

                                onChange('cassette', { name: event.target.value });
                            }}
                            value={cassetteName}
                        >
                            <TranslateOption disabled hidden value="">
                                DRIVETRAIN_CASSETTE_PLACEHOLDER
                            </TranslateOption>
                            {cassetteList.map(({ Name }: any) => (
                                <option key={Name} value={Name}>
                                    {Name}
                                </option>
                            ))}
                            <TranslateOption value="CUSTOM">
                                CUSTOM_DOTTED
                            </TranslateOption>
                        </select>
                    </div>
                </BikeFormRow>
                {isCustom && renderCustomCassette()}
            </div>
        );
    }

    return (
        <div className={styles.container}>
            {renderChainring()}
            {renderCassette()}
        </div>
    );
};

export default DrivetrainInput;
export { CUSTOM_GEARING };
