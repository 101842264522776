import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './TutorialLinkedApps.module.scss';

import Button from '../../components/button';
import Translate from '../../components/translate';

import { bikeComputerToAXS } from '../../assets';
import { SRAM_RED } from '../../constants';

interface TutorialLinkedAppsProps {
    className?: string;
}

const TutorialLinkedApps = ({ className }: TutorialLinkedAppsProps) => {
    const navigate = useNavigate();

    return (
        <div className={className}>
            <div className={styles.header}>
                <Translate>TUTORIAL_LINK_TITLE</Translate>
            </div>
            <div>
                <div className={styles.text}>
                    <Translate>TUTORIAL_LINK_INFO</Translate>
                </div>
                <div className={styles.imageContainer}>
                    <img
                        alt=""
                        className={styles.bikeComputerToAXSImage}
                        src={bikeComputerToAXS}
                    />
                </div>
                <div className={styles.linkedAppsButton}>
                    <Button
                        color={SRAM_RED}
                        style={{ display: 'block', marginLeft: 'auto' }}
                        onClick={() => navigate('/riderportal')}
                    >
                        <Translate>TUTORIAL_LINK_BUTTON</Translate>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default TutorialLinkedApps;
