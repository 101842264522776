import {
    arrayOf,
    number,
    oneOfType,
    string,
} from 'prop-types';
import React from 'react';

import Checkbox from './checkbox';

import Translate from '../../components/translate';

function getLabel(option) {
    const isObject = (typeof option === 'object');

    return isObject ? option.label : option;
}

function getValue(option) {
    const isObject = (typeof option === 'object');

    return isObject ? option.value : option;
}

const CheckboxForm = ({ checked, onChange, options }) => options.map((option) => {
    const label = getLabel(option);
    const value = getValue(option);

    return (
        <Checkbox
            checked={checked.includes(value)}
            key={value}
            onChange={(event) => {
                const newChecked = options
                    .filter((inOption) => {
                        if (getValue(inOption) !== value) {
                            return checked.includes(getValue(inOption));
                        }

                        return event.target.checked;
                    })
                    .map(getValue);

                onChange(newChecked);
            }}
            value={Translate({ children: label })}
        />
    );
});

CheckboxForm.defaultProps = { checked: [] };

CheckboxForm.propTypes = { checked: oneOfType([arrayOf(string), arrayOf(number)]) };

export default CheckboxForm;
