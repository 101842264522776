import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { bool, func } from 'prop-types';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './AccountDropdown.module.scss';
import MenuItem from './menuItem';
import MenuItemButton from './menuItemButton';
import NavBarDropdown from './navBarDropdown';

import ProfileImage from '../../components/profileImage';
import Translate from '../../components/translate';
import { SRAM_RED } from '../../constants';
import { useNexus } from '../../providers/nexus/NexusContext';

const AccountDropdown = ({
    onAuxClick,
    onClick,
    open,
}) => {
    const [error, setError] = useState(false);
    const { cachedProfileImage } = useNexus();
    const location = useLocation();

    return (
        <NavBarDropdown
            buttonClassName={styles.buttonHover}
            containerStyle={{ padding: '0 1rem' }}
            dropdownStyle={{ right: 0 }}
            label={
                (!error && cachedProfileImage
                    ? (
                        <ProfileImage
                            id="data-test-cachedProfilePicture"
                            onError={() => setError(true)}
                        />
                    )
                    : (
                        <PermIdentityIcon
                            className={styles.logo}
                            fontSize="large"
                        />
                    )
                )
            }
            onAuxClick={onAuxClick}
            onClick={onClick}
            open={open}
        >
            <MenuItem className="flex">
                <MenuItemButton
                    onClick={onClick}
                    style={(location.pathname === '/riderportal') ? { color: SRAM_RED } : null}
                    to="/riderportal"
                >
                    <Translate>SETTINGS</Translate>
                </MenuItemButton>
            </MenuItem>
            {/* <MenuItem className="flex">
                <a
                    className={styles.externalLink}
                    href="https://www.sram.com/en/company/campaigns/sram-rider-support"
                    rel="noreferrer noopener"
                    target="_blank"
                >
                    <Translate>HELP_AND_SUPPORT</Translate>
                </a>
            </MenuItem> */}
            <MenuItem className="flex">
                <MenuItemButton onClick={onClick} to="/logout">
                    <Translate>LOG_OUT</Translate>
                </MenuItemButton>
            </MenuItem>
        </NavBarDropdown>
    );
};

AccountDropdown.defaultProps = {
    onAuxClick: null,
    onClick: null,
    open: false,
};

AccountDropdown.propTypes = {
    onAuxClick: func,
    onClick: func,
    open: bool,
};

export default AccountDropdown;
