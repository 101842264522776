import loadImage from 'blueimp-load-image';
import {
    bool, func, node, string,
} from 'prop-types';
import { useEffect } from 'react';
import { Typeahead, TypeaheadInputSingle } from 'react-bootstrap-typeahead';

import styles from './BikeForm.module.scss';

import BikeFormRow from './bikeFormRow';
import CropImageModal from '../cropImageModal';

import Spinner from '../../components/spinner';
import Translate, { useTranslation } from '../../components/translate';
import { iconCamera } from '../../assets';
import {
    AXS_WEB_BG,
    makeCancellable,
    roundValueBy,
    WHITE,
} from '../../constants';
import { useSetState } from '../../hooks';
import { BIKE_SHAPE, useUnits } from '../../providers';

const MAX_WEIGHT = 75;
const BikeForm = ({
    id,
    bike,
    children,
    nameIsRequired,
    onImageSelected,
    onSubmit,
}) => {
    const [state, setState] = useSetState({
        activeRow: null,
        bikeImageCanvas: null,
        bikeModels: {},
        isUploadingImage: false,
        showCropImageModal: false,
        updatedBike: bike,
    });
    const units = useUnits();
    const translate = useTranslation();
    const {
        activeRow,
        bikeImageCanvas,
        bikeModels,
        isUploadingImage,
        showCropImageModal,
        updatedBike,
    } = state;

    let fetchBikeModelsRequest = null;

    const onChange = (key, value) => {
        // prevent rerender
        if (!key || updatedBike[key] === value) return;

        const newUpdatedBike = { ...updatedBike };

        newUpdatedBike[key] = value;

        setState({ updatedBike: newUpdatedBike });
    };

    const onChangeTypeahead = (key, value) => {
        if (!value[0]) return;

        onChange(key, (typeof value[0] === 'string') ? value[0] : value[0].label);
    };

    const onChangeTypeaheadInput = (key, value) => {
        if (value) return;

        onChange(key, value);
    };

    const onImageSelect = (newImage) => {
        if (!newImage) return;

        loadImage(
            newImage,
            (canvas) => setState({ bikeImageCanvas: canvas, isUploadingImage: false, showCropImageModal: true }),
            { canvas: true, orientation: true },
        );
    };

    const onImageCrop = (croppedCanvas) => {
        croppedCanvas.toBlob(
            (blob) => {
                const imageFile = new File([blob], 'croppedBikeImage.jpg');
                onChange('image', imageFile);
            },
            'image/jpeg',
        );

        onImageSelected(croppedCanvas.toDataURL('image/jpeg'));
        setState({ bikeImageCanvas: null, showCropImageModal: !showCropImageModal });
    };

    // Converts the weight to kg as its saved in the database
    const convertWeightToSI = (value) => {
        let newWeight = Number(value);
        newWeight = units.convertWeightToSI(newWeight);

        return newWeight;
    };

    const onFormSubmit = () => {
        const changes = {};

        Object.keys(updatedBike).forEach((key) => {
            if (key === 'data'
                && updatedBike.data !== null
                && typeof updatedBike.data !== 'undefined'
                && typeof updatedBike.data === 'object'
            ) {
                const dataChanges = {};

                Object.keys(updatedBike.data).forEach((dataKey) => {
                    if (updatedBike.data[dataKey] !== (bike.data && bike.data[dataKey])) {
                        dataChanges[dataKey] = updatedBike.data[dataKey];
                    }
                });

                if (Object.keys(dataChanges).length > 0) {
                    changes.data = dataChanges;
                }

                return;
            }

            if (key === 'weight') {
                const metricWeight = convertWeightToSI(updatedBike[key]);

                if (metricWeight !== bike[key]) {
                    changes[key] = metricWeight;
                }

                return;
            }

            if (updatedBike[key] !== bike[key]) {
                changes[key] = updatedBike[key];
            }
        });

        onSubmit(changes);
    };

    const getInputColor = (row) => {
        if (activeRow === row) return WHITE;

        return AXS_WEB_BG;
    };

    const getRowColor = (row) => {
        if (activeRow === row) return AXS_WEB_BG;

        return WHITE;
    };

    // Use this method to retrieve the staged change or the current value
    // from the nexusUserProfile
    const getValue = (key) => {
        if (updatedBike[key] === null || typeof updatedBike[key] === 'undefined') return '';

        return updatedBike[key];
    };

    const setActiveRow = (row) => {
        setState({ activeRow: row });
    };

    const fetchBikeModels = async () => {
        if (fetchBikeModelsRequest) {
            try {
                const data = fetchBikeModelsRequest.promise;

                return data;
            } catch (error) {
                return null;
            }
        }

        fetchBikeModelsRequest = makeCancellable(import('../../assets/data/bikeModels.json'));

        try {
            const data = await fetchBikeModelsRequest.promise;

            setState({ bikeModels: data.default });

            fetchBikeModelsRequest = null;

            return data;
        } catch (error) {
            if (!error.isCancelled) {
                fetchBikeModelsRequest = null;
            }

            return null;
        }
    };

    // Converts the weight from kg as its saved in the database
    const convertWeightFromSI = (value) => {
        let newWeight = Number(value);
        newWeight = units.convertWeightFromSI(newWeight);
        newWeight = roundValueBy(newWeight, 2);

        if (!Number.isFinite(newWeight)) newWeight = '';

        return newWeight;
    };

    const renderCropImageModal = () => (
        <CropImageModal
            imageCanvas={bikeImageCanvas}
            onCancel={() => setState({ bikeImageCanvas: null, showCropImageModal: !showCropImageModal })}
            onCrop={(croppedCanvas) => onImageCrop(croppedCanvas)}
            open={showCropImageModal}
        />
    );

    const renderWeightInput = () => {
        const max = Math.floor(units.convertWeightFromSI(MAX_WEIGHT));

        const weight = getValue('weight');

        return (
            <BikeFormRow
                label="PROFILE_WEIGHT"
                onFocus={() => setActiveRow('weight')}
                style={{ background: getRowColor('weight') }}
            >
                <input
                    id="data-test-bike-weight"
                    className={styles.input}
                    max={max}
                    min={0}
                    onChange={(event) => onChange('weight', event.target.value)}
                    placeholder="---"
                    required
                    step={0.01}
                    style={{ background: getInputColor('weight'), width: '5rem' }}
                    type="number"
                    value={weight}
                />
                <div className={styles.units}>
                    <Translate>{units.getLabelWeight().shorthand}</Translate>
                </div>
            </BikeFormRow>
        );
    };

    useEffect(() => {
        fetchBikeModels();

        if (updatedBike) {
            setState({
                updatedBike: {
                    ...updatedBike,
                    weight: convertWeightFromSI(updatedBike.weight),
                },
            });
        }
    }, []);

    return (
        <form
            id={id}
            onSubmit={(event) => {
                event.preventDefault();
                onFormSubmit();
            }}
        >
            <Spinner loading={isUploadingImage} />
            <BikeFormRow label="PHOTO">
                <div className={styles.imagePickerContainer}>
                    <input
                        accept="image/*"
                        className={styles.imagePicker}
                        onChange={(event) => {
                            onImageSelect(event.target.files[0]);
                            // eslint-disable-next-line no-param-reassign
                            event.target.value = '';
                            setState({ isUploadingImage: true });
                        }}
                        type="file"
                    />
                    <img alt="" className={styles.imagePickerIcon} src={iconCamera} />
                    <Translate>PHOTO_SELECT</Translate>
                </div>
            </BikeFormRow>
            <BikeFormRow
                label="BIKE_NAME"
                onFocus={() => setActiveRow('name')}
                style={{ background: getRowColor('name') }}
            >
                <input
                    id="data-test-bike-name"
                    className={styles.input}
                    onChange={(event) => onChange('name', event.target.value.substr(0, 28))}
                    placeholder={translate('NEW_BIKE_NAME_PLACEHOLDER')}
                    required={nameIsRequired}
                    style={{ background: getInputColor('name') }}
                    type="text"
                    value={getValue('name')}
                />
            </BikeFormRow>
            <BikeFormRow
                label="BRAND"
                onFocus={() => setActiveRow('brand')}
                style={{ background: getRowColor('brand') }}
            >
                <Typeahead
                    allowNew
                    className={styles.typeaheadInput}
                    defaultInputValue={getValue('brand')}
                    id="brand"
                    onChange={(value) => onChangeTypeahead('brand', value)}
                    onInputChange={(value) => onChangeTypeaheadInput('brand', value)}
                    options={Object.keys(bikeModels)}
                    placeholder={translate('NEW_BIKE_BRAND_PLACEHOLDER')}
                    renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
                        <TypeaheadInputSingle
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...inputProps}
                            inputRef={inputRef}
                            referenceElementRef={referenceElementRef}
                            shouldSelectHint
                        />
                    )}
                />
            </BikeFormRow>
            <BikeFormRow
                label="MODEL"
                onFocus={() => setActiveRow('model')}
                style={{ background: getRowColor('model') }}
            >
                <Typeahead
                    allowNew
                    className={styles.typeaheadInput}
                    defaultInputValue={getValue('model')}
                    id="model"
                    onChange={(value) => onChangeTypeahead('model', value)}
                    onInputChange={(value) => onChangeTypeaheadInput('model', value)}
                    options={(getValue('brand')
                        ? (bikeModels[getValue('brand')] || [])
                        : Object.values(bikeModels).flat().sort()
                    )}
                    placeholder={translate('NEW_BIKE_MODEL_PLACEHOLDER')}
                    renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
                        <TypeaheadInputSingle
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...inputProps}
                            inputRef={inputRef}
                            referenceElementRef={referenceElementRef}
                            shouldSelectHint
                        />
                    )}
                />
            </BikeFormRow>
            <BikeFormRow
                label="BIKE_MODEL_YEAR"
                onFocus={() => setActiveRow('year')}
                style={{ background: getRowColor('year') }}
            >
                <input
                    id="data-test-bike-model-year"
                    className={styles.input}
                    max={2500}
                    min={1900}
                    onChange={(event) => onChange('year', event.target.value)}
                    placeholder={translate('NEW_BIKE_MODEL_YEAR_PLACEHOLDER')}
                    style={{ background: getInputColor('year'), width: '100%' }}
                    type="number"
                    value={getValue('year')}
                />
            </BikeFormRow>
            {renderWeightInput()}
            {renderCropImageModal()}
            {children}
        </form>
    );
};

BikeForm.defaultProps = {
    bike: {},
    children: null,
    id: '',
    nameIsRequired: false,
    onImageSelected: () => { /* do nothing */ },
    onSubmit: () => { /* do nothing */ },
};

BikeForm.propTypes = {
    bike: BIKE_SHAPE,
    children: node,
    id: string,
    nameIsRequired: bool,
    onImageSelected: func,
    onSubmit: func,
};

export default BikeForm;
