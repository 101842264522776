import React from 'react';

import { iconAlert, iconWarning } from '../../assets';

interface BikeAlertBadgeProps {
    className?: string;
    notificationSet?: any[];
    show: boolean;
}

const BikeAlertBadge = ({ notificationSet = [], className = '', show = true }: BikeAlertBadgeProps) => {
    if (!notificationSet?.length || !show) return null;

    const alarms = notificationSet.filter(({ notification_type }) => notification_type === 'alarm');

    if (alarms.length) {
        return (
            <img alt="" className={className} src={iconAlert} />
        );
    }

    const services = notificationSet.filter(({ notification_type }) => notification_type === 'service');

    if (services.length) {
        return (
            <img alt="" className={className} src={iconWarning} />
        );
    }

    return null;
};

export default BikeAlertBadge;
