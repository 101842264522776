import React from 'react';
import styles from './RideStyleChip.module.scss';
import Translate from '../../../components/translate';
import {
    RED,
    WHITE,
} from '../../../constants/colours';

interface RideStyleChipProps {
    rideStyle: string;
    Icon: any;
    onSelect: () => void;
    selected: boolean;
}

const RideStyleChip = ({
    rideStyle, Icon, onSelect, selected,
}: RideStyleChipProps) => (
    <div
        className={`${styles.chipContainer} ${selected ? styles.selected : ''}`}
        onClick={() => onSelect()}
        onKeyDown={onSelect}
        role="button"
        tabIndex={0}
        id={rideStyle}
    >
        <div className={styles.icon}>
            <Icon
                fill={selected ? WHITE : RED}
            />
        </div>
        <div className={styles.title}>
            <Translate>{rideStyle}</Translate>
        </div>
    </div>
);

export default RideStyleChip;
