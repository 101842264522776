import { bool } from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';

import AxsLogo from './axsLogo';
import styles from './NavBar.module.scss';
import NavControls from './navControls';

const Navbar = ({ hideControls }) => {
    const { pathname } = useLocation();
    const excludedPaths = ['/guides/mobile/fullmount/chain/calculator', '/mobile/riderportal'];
    const isExcluded = excludedPaths.some((path) => pathname && pathname.startsWith(path));

    if (isExcluded) return null;

    return (
        <div className={styles.container}>
            <div className={styles.logoContainer}>
                <AxsLogo />
            </div>
            {!hideControls && <NavControls />}
        </div>
    );
};

Navbar.defaultProps = { hideControls: false };

Navbar.propTypes = { hideControls: bool };

export default Navbar;
