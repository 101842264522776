import { withSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

import { func } from 'prop-types';
import styles from './NotificationSnackbar.module.scss';

import {
    iconAchievement,
    iconAlert,
    iconWarning,
    logoAxsLogo,
} from '../../assets';
import {
    NOTIFICATION_SHAPE,
    useActivities,
    useNotifications,
} from '../../providers';

const NotificationSnackbar = ({
    closeSnackbar,
    notification,
}) => {
    const { fetchActivity } = useActivities();
    const notifications = useNotifications();
    const getActivityId = () => {
        if (!notification.data || !notification.data.activity_id) {
            return null;
        }

        return notification.data.activity_id;
    };

    const getRoute = () => {
        const activityId = getActivityId();

        if (activityId) {
            return `/activities/${activityId}`;
        }

        if (notification.component) {
            return `/component/${notification.component}`;
        }

        if (notification.bike) {
            return `/bikerack/${notification.bike}`;
        }

        return '/messages';
    };

    useEffect(() => {
        const activityId = getActivityId();

        if (activityId) {
            fetchActivity(activityId, true);
        }
    }, []);

    const updateNotification = async () => {
        notifications.updateNotification(
            notification.id,
            { view_ts: Math.floor(Date.now() / 1000) },
        );
    };

    const renderIcon = () => {
        switch (notification.notification_type) {
            case 'achievement':
                return <img alt="" className={styles.alertImage} src={iconAchievement} />;
            case 'alarm':
                return <img alt="" className={styles.alertImage} src={iconAlert} />;
            case 'activity':
                return <img alt="" className={styles.alertImage} src={logoAxsLogo} />;
            case 'service':
                return <img alt="" className={styles.alertImage} src={iconWarning} />;
            default:
                return null;
        }
    };

    return (
        <Link
            className={styles.container}
            onClick={() => {
                updateNotification();
                closeSnackbar(notification.id);
            }}
            to={getRoute()}
        >
            {renderIcon()}
            <div className={styles.content}>
                <div className={styles.title}>
                    {notification.title}
                </div>
                <div className={styles.dateContainer}>
                    <Moment format="MMM Do, h:mm A" unix>
                        {notification.create_ts}
                    </Moment>
                </div>
            </div>
        </Link>
    );
};

NotificationSnackbar.propTypes = {
    closeSnackbar: func,
    notification: NOTIFICATION_SHAPE.isRequired,
};

export default withSnackbar(NotificationSnackbar);
