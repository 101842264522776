import styles from './TireSettings.module.scss';
import Translate from '../../../components/translate';
import CheckBox from '../../../components/checkbox';
import {
    SRAM_RED,
} from '../../../constants/colours';
import TranslateOption from '../../../components/translateOption';
import {
    getCasingOptions,
    getGoodyearTireWidthSliderValues,
    getTireWidthSliderBaseValues,
    getTireWidthSliderValues,
    getTireWidthSteps,
    isGoodYearTire,
} from '../tyrePressureGuideHelpers2';

import Slider from '../slider';

interface TireSettingsProps {
    checkedPreset: boolean,
    onChangePreset: () => void,
    frontTireCasing: string,
    frontTireWidth: string | number,
    isTireInches: boolean,
    onChange: (args: any) => void,
    rearTireCasing: string,
    rearTireWidth: string | number,
    rideStyle: string,
    showFrontTireWidthWarning: boolean,
    showRearTireWidthWarning: boolean,
    rimType: string;
}

const TireSettings = ({
    checkedPreset,
    onChangePreset,
    frontTireCasing,
    frontTireWidth,
    isTireInches,
    onChange,
    rearTireCasing,
    rearTireWidth,
    rideStyle,
    showFrontTireWidthWarning,
    showRearTireWidthWarning,
    rimType,
}: TireSettingsProps) => {
    const handleChangeTireCasing = (key: string, value: string) => {
        if (!isGoodYearTire(value)) {
            onChange({ [key]: value });
            return;
        }

        const tireWidth = getTireWidthSliderBaseValues(value);

        if (key === 'frontTireCasing') {
            onChange({
                frontTireCasing: value,
                frontTireWidth: tireWidth,
                innerRimWidth: 25,
                rimType: 'RIM_TYPE_TUBELESS_STRAIGHT_SIDE',
                surface: 'SURFACE_DRY',
                wheelDiameter: 622,
            });
        }
        if (key === 'rearTireCasing') {
            onChange({
                innerRimWidth: 25,
                rearTireCasing: value,
                rearTireWidth: tireWidth,
                rimType: 'RIM_TYPE_TUBELESS_STRAIGHT_SIDE',
                surface: 'SURFACE_DRY',
                wheelDiameter: 622,
            });
        }
    };

    const setTireWidth = (value: number, frontTire: boolean) => {
        if (frontTire) {
            return onChange({ frontTireWidth: value });
        }
        return onChange({ rearTireWidth: value });
    };

    const renderRideStylePreset = () => (
        <div className={styles.usePresetValuesContainer} key="rideStylePresetBtn1">
            <div className={styles.checkboxContainer}>
                <CheckBox
                    disabled={!rideStyle}
                    checked={checkedPreset}
                    id="tireSettings__use-preset-values"
                    onChange={onChangePreset}
                    style={{ justifyContent: 'flex-end', opacity: !rideStyle ? 0.5 : 1 }}
                />
            </div>
            {/* jsx-a11y/label-has-associated-control disabled because not nesting label
                    is intentional here and the ID shouldn't exist twice
                */}
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label
                className={styles.usePresetValuesLabel}
                style={{ opacity: !rideStyle ? 0.5 : 1 }}
                htmlFor="tireSettings__use-preset-values"
            >
                <Translate>TIRE_PRESSURE_GUIDE_USE_PRESET</Translate>
            </label>
        </div>
    );

    const renderTireCasingInput = (frontTire: boolean) => {
        const casingOptions = getCasingOptions(rideStyle);
        const key = frontTire ? 'frontTireCasing' : 'rearTireCasing';

        return (
            <div className={styles.section} key={frontTire ? 'front-tire-casing' : 'rear-tire-casing'}>
                <Translate>{frontTire ? 'FRONT_TIRE_CASING' : 'TIRE_CASING_REAR'}</Translate>
                <div className={styles.inputContainer}>
                    <select
                        data-testid={frontTire ? 'front-tire-casing' : 'rear-tire-casing'}
                        className={styles.inputField}
                        onChange={(event) => handleChangeTireCasing(key, event.target.value)}
                        required
                        value={frontTire ? frontTireCasing : rearTireCasing}
                    >
                        <TranslateOption disabled hidden value="">SELECT</TranslateOption>
                        {casingOptions.map((casingOption) => (
                            <TranslateOption key={casingOption} value={casingOption}>
                                {casingOption}
                            </TranslateOption>
                        ))}
                    </select>
                </div>
            </div>
        );
    };

    const renderTireWidthSlider = (frontTire: boolean) => {
        const units = isTireInches ? 'UNITS_INCHES_SHORT' : 'UNITS_MM_SHORT';
        const isFatBike = rideStyle === 'RIDE_STYLE_FAT';
        const frontTireStep = getTireWidthSteps(frontTireCasing, isTireInches, isFatBike);
        const rearTireStep = getTireWidthSteps(rearTireCasing, isTireInches, isFatBike);
        const frontTireWidths = isGoodYearTire(frontTireCasing)
            ? getGoodyearTireWidthSliderValues(frontTireCasing) : getTireWidthSliderValues(rideStyle, rimType);
        const rearTireWidths = isGoodYearTire(rearTireCasing)
            ? getGoodyearTireWidthSliderValues(rearTireCasing) : getTireWidthSliderValues(rideStyle, rimType);
        const showWarning = frontTire ? showFrontTireWidthWarning : showRearTireWidthWarning;

        return (
            <div className={styles.section} key={frontTire ? 'front-tire-width' : 'rear-tire-width'}>
                <Translate>{frontTire ? 'FRONT_TIRE_WIDTH' : 'TIRE_WIDTH_REAR'}</Translate>
                <div className={styles.inputContainer}>
                    <Slider
                        className={styles.slider}
                        id={frontTire ? 'front-tire-width-slider' : 'rear-tire-width-slider'}
                        min={frontTire ? frontTireWidths.minTireWidth : rearTireWidths.minTireWidth}
                        max={frontTire ? frontTireWidths.maxTireWidth : rearTireWidths.maxTireWidth}
                        onChange={(value: number) => setTireWidth(value, frontTire)}
                        step={frontTire ? frontTireStep : rearTireStep}
                        value={Number(frontTire ? frontTireWidth : rearTireWidth)}
                        valueLabelDisplay="auto"
                    />
                    <div
                        className={styles.tireWidthValues}
                        style={showWarning ? { color: SRAM_RED, fontWeight: 'bold' } : {}}
                    >
                        <span>{frontTire ? frontTireWidth : rearTireWidth}</span>
                        &nbsp;
                        <Translate>{units}</Translate>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <Translate>TIRES</Translate>
            {renderRideStylePreset()}
            {renderTireCasingInput(true)}
            {renderTireWidthSlider(true)}
            {renderTireCasingInput(false)}
            {renderTireWidthSlider(false)}
        </div>
    );
};

export default TireSettings;
