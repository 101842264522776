import React from 'react';

import { bool, string } from 'prop-types';
import styles from './TutorialPairing.module.scss';

import Translate from '../../components/translate';

import { pairGearingToBikeComputer, pairGearingToBikeComputerAndAXS } from '../../assets';
import { BLUE_MILD } from '../../constants';

const PAIR_LINK = 'https://www.sram.com/en/sram/road/campaigns/pairing-garmin-and-wahoo-with-gearing';

interface TutorialPairingProps {
    className?: string;
    showAXS?: boolean;
}

const TutorialPairing = ({ className, showAXS }: TutorialPairingProps) => (
    <div className={className}>
        <div className={styles.header}>
            <Translate>TUTORIAL_PAIR_TITLE</Translate>
        </div>
        <div className={styles.text}>
            <p>
                <Translate>TUTORIAL_PAIR_INFO_ONE</Translate>
            </p>
        </div>
        <div className={styles.imageContainer}>
            <img
                alt=""
                className={styles.pairComponentImage}
                src={(showAXS
                    ? pairGearingToBikeComputerAndAXS
                    : pairGearingToBikeComputer
                )}
            />
        </div>
        <div className={styles.text}>
            <p>
                <Translate
                    params={{
                        pairLink: (
                            <a
                                href={PAIR_LINK}
                                rel="noreferrer noopener"
                                style={{ color: BLUE_MILD }}
                                target="_blank"
                            >
                                <Translate>TUTORIAL_PAIR_INFO_TWO_PAIR_LINK</Translate>
                            </a>
                        ),
                    }}
                >
                    TUTORIAL_PAIR_INFO_TWO
                </Translate>
            </p>
        </div>
    </div>
);

TutorialPairing.propTypes = {
    className: string,
    showAXS: bool,
};

export default TutorialPairing;
