import React from 'react';

import styles from './BikeFormRow.module.scss';

import Translate from '../../../components/translate';

interface BikeFormRowProps {
    children: React.ReactNode;
    className?: string;
    contentClassName?: string;
    contentStyle?: React.CSSProperties;
    image?: string;
    label?: string;
    labelClassName?: string;
    onFocus?: () => void;
    style?: React.CSSProperties;
}

const BikeFormRow = ({
    children,
    className,
    contentClassName,
    contentStyle,
    image,
    label,
    labelClassName,
    onFocus,
    style,
}: BikeFormRowProps) => (
    <div
        className={`${styles.container} ${className}`}
        onFocus={onFocus}
        style={style}
    >
        <div className={`${styles.labelContainer} ${labelClassName}`}>
            {image && <img alt="" className={styles.image} src={image} />}
            {label && (
                <div className={styles.text}>
                    <Translate>{label}</Translate>
                </div>
            )}
        </div>
        <div
            className={`${styles.content} ${contentClassName}`}
            style={contentStyle}
        >
            {children}
        </div>
    </div>
);

BikeFormRow.defaultProps = {
    onFocus: () => {},
};

export default BikeFormRow;
